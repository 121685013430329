import React from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel3";
import teamOne from "../../assets/images/team-1.jpg";
import teamTwo from "../../assets/images/team-2.jpg";
import teamThree from "../../assets/images/team-3.jpg";
import teamFour from "../../assets/images/team-4.jpg";
import teamFive from "../../assets/images/team-5.jpg";
import teamSix from "../../assets/images/team-6.jpg";

const options = {
  loop: true,
  autoplay: true,
  nav: false,
  margin: 30,
  mouseDrag: true,
  autoplayHoverPause: true,
  responsiveClass: true,
  dots: true,
  navText: [
    "<i class='fa fa-angle-left'></i>",
    "<i class='fa fa-angle-right'></i>",
  ],
  responsive: {
    0: {
      items: 1,
    },
    576: {
      items: 2,
    },
    768: {
      items: 2,
    },
    1024: {
      items: 3,
    },
  },
};

class Team extends React.Component {
  render() {
    return (
      <section id="team" className="team-area ptb-80">
        <div className="container">
          <div className="section-title">
            <h4>Meet Archies</h4>
            <h2>
              Our Creative <span>Team</span>
            </h2>
            <p>
              Behind every line of code, every innovative solution, and every
              successful project is a team of passionate individuals. Get to
              know the faces behind Arch Technologies — the driving force that
              propels us forward.
            </p>
          </div>

          <div className="row">
            <OwlCarousel
              className="team-slider owl-carousel owl-theme"
              {...options}
            >
              <div className="col-lg-12">
                <div className="our-team">
                  <div className="pic">
                    <img src={teamOne} alt="team-img" />
                  </div>

                  <div className="team-content">
                    <h3 className="title">Lewis Arch</h3>
                    <span className="post">CEO</span>
                  </div>

                  <div className="social">
                    <ul>
                      <li>
                        <Link to="#" className="fa fa-facebook"></Link>
                      </li>
                      <li>
                        <Link to="#" className="fa fa-google-plus"></Link>
                      </li>
                      <li>
                        <Link to="#" className="fa fa-instagram"></Link>
                      </li>
                      <li>
                        <Link to="#" className="fa fa-linkedin"></Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="our-team">
                  <div className="pic">
                    <img src={teamTwo} alt="team-img" />
                  </div>

                  <div className="team-content">
                    <h3 className="title">Kapil Chotwani</h3>
                    <span className="post">Director of Engineering</span>
                  </div>

                  <div className="social">
                    <ul>
                      <li>
                        <Link to="#" className="fa fa-facebook"></Link>
                      </li>
                      <li>
                        <Link to="#" className="fa fa-google-plus"></Link>
                      </li>
                      <li>
                        <Link to="#" className="fa fa-instagram"></Link>
                      </li>
                      <li>
                        <Link to="#" className="fa fa-linkedin"></Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="our-team">
                  <div className="pic">
                    <img src={teamThree} alt="team-img" />
                  </div>

                  <div className="team-content">
                    <h3 className="title">Andrea Dahlberg</h3>
                    <span className="post">VP Operations</span>
                  </div>

                  <div className="social">
                    <ul>
                      <li>
                        <Link to="#" className="fa fa-facebook"></Link>
                      </li>
                      <li>
                        <Link to="#" className="fa fa-google-plus"></Link>
                      </li>
                      <li>
                        <Link to="#" className="fa fa-instagram"></Link>
                      </li>
                      <li>
                        <Link to="#" className="fa fa-linkedin"></Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="our-team">
                  <div className="pic">
                    <img src={teamFour} alt="team-img" />
                  </div>

                  <div className="team-content">
                    <h3 className="title">Evan Kirsch</h3>
                    <span className="post">Customer Success Manager</span>
                  </div>

                  <div className="social">
                    <ul>
                      <li>
                        <Link to="#" className="fa fa-facebook"></Link>
                      </li>
                      <li>
                        <Link to="#" className="fa fa-google-plus"></Link>
                      </li>
                      <li>
                        <Link to="#" className="fa fa-instagram"></Link>
                      </li>
                      <li>
                        <Link to="#" className="fa fa-linkedin"></Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="our-team">
                  <div className="pic">
                    <img src={teamFive} alt="team-img" />
                  </div>

                  <div className="team-content">
                    <h3 className="title">Kyle Arch</h3>
                    <span className="post">SEO Specialist</span>
                  </div>

                  <div className="social">
                    <ul>
                      <li>
                        <Link to="#" className="fa fa-facebook"></Link>
                      </li>
                      <li>
                        <Link to="#" className="fa fa-google-plus"></Link>
                      </li>
                      <li>
                        <Link to="#" className="fa fa-instagram"></Link>
                      </li>
                      <li>
                        <Link to="#" className="fa fa-linkedin"></Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="our-team">
                  <div className="pic">
                    <img src={teamSix} alt="team-img" />
                  </div>

                  <div className="team-content">
                    <h3 className="title">Collette Jay</h3>
                    <span className="post">Social Media Manager</span>
                  </div>

                  <div className="social">
                    <ul>
                      <li>
                        <Link to="#" className="fa fa-facebook"></Link>
                      </li>
                      <li>
                        <Link to="#" className="fa fa-google-plus"></Link>
                      </li>
                      <li>
                        <Link to="#" className="fa fa-instagram"></Link>
                      </li>
                      <li>
                        <Link to="#" className="fa fa-linkedin"></Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </section>
    );
  }
}

export default Team;
