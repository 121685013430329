import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

class MainBanner extends React.Component {
  render() {
    return (
      <div id="home" className="main-banner item-bg-one">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="main-banner-text">
                    <h4>We Are Creative</h4>
                    <h1>
                      The Best <span>Digital</span> Experience
                    </h1>
                    <p>Ready to experience hypergrowth? Let's talk!</p>
                    <AnchorLink href="#welcome" className="btn btn-primary">
                      I WANT TO GROW
                    </AnchorLink>
                    <AnchorLink
                      href="#work"
                      className="btn btn-primary view-work"
                    >
                      View Work
                    </AnchorLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MainBanner;
