import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Ctr = () => {
  return (
    <section className="ctr-area ptb-80">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="section-title mb-0">
              <h4>Looking for exclusive services?</h4>
              <h2>Get The Best For Your Business</h2>
              <p>
                Join hands with Arch Technologies and experience the difference
                of having a dedicated partner committed to the success of your
                business. Elevate your brand, maximize your potential, and get
                the best for your business.
              </p>
              <AnchorLink href="#contact" className="btn btn-primary">
                Contact
              </AnchorLink>
              <AnchorLink href="#work" className="btn btn-primary view-work">
                View Work
              </AnchorLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Ctr;
