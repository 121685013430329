import React from "react";
import ModalVideo from "react-modal-video";
import { Link } from "react-router-dom";
import aboutImg from "../../assets/images/about.jpg";

class About extends React.Component {
  state = {
    isOpen: false,
  };

  openModal = () => {
    this.setState({ isOpen: true });
  };
  render() {
    return (
      <section id="about" className="about-area ptb-80 bg-f6f6f6">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              {/*<div className="about-addax">*/}
              {/*    <div className="section-title">*/}
              {/*        <h2>We Are Digital <span>Marketing & Branding</span></h2>*/}
              {/*        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>*/}
              {/*    </div>*/}

              {/*    <ul className="pull-left">*/}
              {/*        <li><i className="fa fa-check"></i>Creative Design</li>*/}
              {/*        <li><i className="fa fa-check"></i>Retina Ready</li>*/}
              {/*        <li><i className="fa fa-check"></i>Responsive Design</li>*/}
              {/*    </ul>*/}

              {/*    <ul>*/}
              {/*        <li><i className="fa fa-check"></i>Modern Design</li>*/}
              {/*        <li><i className="fa fa-check"></i>Awesome Design</li>*/}
              {/*        <li><i className="fa fa-check"></i>Digital Marketing & Branding</li>*/}
              {/*    </ul>*/}

              {/*    <Link to="#" className="btn btn-primary">Read More</Link>*/}
              {/*</div>*/}
              <div className="section-title">
                <h2>
                  About <span>Arch</span>
                </h2>
                <p>
                  At Arch Technologies, we are at the forefront of innovation,
                  harnessing the power of technology to drive your success in
                  the digital realm. With a passion for excellence and a
                  commitment to delivering cutting-edge IT solutions, we are
                  your trusted partner in navigating the complexities of the
                  modern business landscape.
                </p>
                <br />
                <p>
                  we are not just building software; we are building a community
                  of forward-thinkers and innovators. If you're passionate about
                  technology, thrive on challenges, and believe in a culture
                  that empowers you to be your best, we invite you to join us in
                  shaping the future of IT.
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="about-video">
                <img src={aboutImg} alt="about" />
                <div className="video-btn">
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      this.openModal();
                    }}
                    to="#"
                    className="popup-youtube"
                  >
                    <i className="fa fa-play"></i>
                  </Link>
                  <ModalVideo
                    channel="youtube"
                    isOpen={this.state.isOpen}
                    videoId="bk7McNUjWgw"
                    onClose={() => this.setState({ isOpen: false })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
