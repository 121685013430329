import React from "react";

class Price extends React.Component {
  render() {
    return (
      <section id="price" className="price-area ptb-80 bg-f6f6f6">
        <div className="container">
          <div className="section-title">
            <h2>
              Our <span>SEO Plans Pricing</span>
            </h2>
            <p>
              We offer comprehensive SEO plans designed to boost your online
              visibility, drive traffic, and increase conversions. Choose from
              our range of packages to find the perfect fit for your business.
            </p>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="pricingTable">
                <h3 className="title">Foundation</h3>
                <div className="price-value">
                  <span className="currency">$</span>
                  <span className="amount">499.00</span>
                  <span className="month">/Per month</span>
                </div>
                <ul className="pricing-content">
                  <li>
                    <i className="fa fa-check"></i> Keyword research and
                    analysis.
                  </li>
                  <li>
                    <i className="fa fa-check"></i> On-page optimization (meta
                    tags, content optimization, etc.).
                  </li>
                  <li>
                    <i className="fa fa-check"></i> Local SEO optimization (if
                    applicable).
                  </li>
                  <li>
                    <i className="fa fa-check"></i> Basic website audit and
                    recommendations.
                  </li>
                </ul>
                <a
                  href="https://buy.stripe.com/8wM7vHcZh0uZ9X23cg"
                  className="btn btn-primary"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Order Now
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="pricingTable">
                <h3 className="title">Premium</h3>
                <div className="price-value">
                  <span className="currency">$</span>
                  <span className="amount">1499.00</span>
                  <span className="month">/Per month</span>
                </div>
                <ul className="pricing-content">
                  <li>
                    <i className="fa fa-check"></i> All features of the
                    Foundation Plan, plus:
                  </li>
                  <li>
                    <i className="fa fa-check"></i> Comprehensive website audit
                  </li>
                  <li>
                    <i className="fa fa-check"></i> Content strategy development
                    and implementation.
                  </li>
                  <li>
                    <i className="fa fa-check"></i> Backlink building and
                    outreach.
                  </li>
                </ul>
                <a
                  href="https://buy.stripe.com/8wM17jcZhb9DfhmbIN"
                  className="btn btn-primary"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Order Now
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
              <div className="pricingTable">
                <h3 className="title">Elite</h3>
                <div className="price-value">
                  <span className="currency">$</span>
                  <span className="amount">2999.00</span>
                  <span className="month">/Per month</span>
                </div>
                <ul className="pricing-content">
                  <li>
                    <i className="fa fa-check"></i> All features of the Premium
                    Plan, plus:
                  </li>
                  <li>
                    <i className="fa fa-check"></i> Advanced keyword research
                    and competitor analysis.
                  </li>
                  <li>
                    <i className="fa fa-check"></i> Customized link-building
                    campaigns.
                  </li>
                  <li>
                    <i className="fa fa-check"></i>Dedicated account manager and
                    priority support.
                  </li>
                </ul>
                <a
                  href="https://buy.stripe.com/14k8zL0cv2D7fhmcMS"
                  className="btn btn-primary"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Order Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Price;
