import React from "react";
import MainBanner from "../PageBanners/MainBannerOne";
import Welcome from "../Common/Welcome";
import About from "../Common/About";
import Strategy from "../Common/Strategy";
import WhoWeAre from "../Common/WhoWeAre";
import Ctr from "../Common/Ctr";
import Skill from "../Common/Skill";
import Team from "../Common/Team";
import Services from "../Common/Services";
import Price from "../Common/Price";
import Contact from "../Common/Contact";
import Footer from "../Common/Footer";
import GoTop from "../Common/GoTop";

class HomeOne extends React.Component {
  render() {
    return (
      <>
        {/* Main Banner */}
        <MainBanner />

        {/* Welcome Area */}
        <Welcome />

        {/* About Area */}
        <About />

        {/* Strategy Area */}
        <Strategy />

        {/* Who We Are Area */}
        <WhoWeAre />

        {/* CTR Area */}
        <Ctr />

        {/* Skill Area */}
        <Skill />

        {/* Team Area */}
        <Team />

        {/* Services Area */}
        <Services />

        {/* Price Area */}
        <Price />

        {/* Contact Area */}
        <Contact />

        {/* Footer Area */}
        <Footer />
        <GoTop scrollStepInPx="50" delayInMs="16.66" />
      </>
    );
  }
}

export default HomeOne;
