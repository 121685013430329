import React from "react";
import { Link } from "react-router-dom";

class Welcome extends React.Component {
  render() {
    return (
      <section id="welcome" className="welcome-area ptb-80">
        <div className="container">
          <div className="section-title">
            <h4>We are creative</h4>
            <h2>
              Welcome to <span>Arch</span>
            </h2>
            <p>
              Embark on a journey of digital excellence with [Your Company
              Name]. Whether you're a startup looking to make your mark or an
              established enterprise seeking innovation, we have the expertise
              to propel you forward.
            </p>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-box">
                <i className="fa fa-pencil-square-o icon"></i>
                <h3>Digital Transformation</h3>
                <p>
                  Embrace the future with our digital transformation solutions.
                  We seamlessly integrate technology into your business
                  processes and optimizing efficiency.
                </p>
                <Link to="#" title="Read More" className="link-btn">
                  <i className="fa fa-arrow-right"></i>
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single-box">
                <i className="fa fa-laptop icon"></i>
                <h3>Content Management </h3>
                <p>
                  Elevate your content game with our state-of-the-art Content
                  Management Systems (CMS). Take control of your digital
                  narrative and engage your audience like never before.
                </p>
                <Link to="#" title="Read More" className="link-btn">
                  <i className="fa fa-arrow-right"></i>
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
              <div className="single-box">
                <i className="fa fa-life-ring icon"></i>
                <h3>Dedicated Support</h3>
                <p>
                  Our team of experts is here for you every step of the way.
                  From implementation to ongoing support, we ensure a seamless
                  experience for our clients.
                </p>
                <Link to="#" title="Read More" className="link-btn">
                  <i className="fa fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Welcome;
