import React from "react";
import { Link } from "react-router-dom";

class Strategy extends React.Component {
  render() {
    return (
      <section className="strategy-area">
        <div className="container-fluid p-0">
          <div className="row ">
            <div className="col-lg-6 col-md-5">
              <div className="image"></div>
            </div>

            <div className="col-lg-6 col-md-7">
              <div className="about-strategy ptb-80">
                <div className="section-title">
                  <h2>
                    Our <span>Core Values</span>
                  </h2>
                  <p>
                    our culture is more than just a set of values; it's the
                    driving force behind everything we do. We believe in
                    creating an environment where passion, collaboration, and
                    innovation converge to redefine the possibilities in the IT
                    landscape.
                  </p>
                </div>

                <ul>
                  <li>
                    <i className="fa fa-check"></i>Innovation First
                  </li>
                  <li>
                    <i className="fa fa-check"></i>Collaboration Matters
                  </li>
                  <li>
                    <i className="fa fa-check"></i>Empowering Excellence
                  </li>
                  <li>
                    <i className="fa fa-check"></i>Continuous Learning
                  </li>
                  <li>
                    <i className="fa fa-check"></i>People First
                  </li>
                </ul>

                <Link to="#" className="btn btn-primary">
                  Read More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Strategy;
