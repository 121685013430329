import React from "react";

class WhoWeAre extends React.Component {
  render() {
    return (
      <section className="who-we-are ptb-80 bg-f6f6f6">
        <div className="container">
          <div className="section-title">
            <h2>
              Who We <span>Are</span>
            </h2>
            <p>
              Where technology meets innovation, and passion drives progress. We
              are not just an IT company; we are a community of dedicated
              professionals united by a common vision — to redefine what's
              possible in the digital landscape.
            </p>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-who-we-are">
                <i className="fa fa-users"></i>
                <h4>We are Innovative</h4>
                <p>
                  We embrace the spirit of innovation, constantly pushing
                  boundaries, and seeking new ways to shape the future of
                  technology.
                </p>
                <span>1</span>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single-who-we-are">
                <i className="fa fa-lightbulb-o"></i>
                <h4>We are Collaborative</h4>
                <p>
                  We believe in the power of teamwork and the collective
                  strength of diverse perspectives.
                </p>
                <span>2</span>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single-who-we-are">
                <i className="fa fa-tag"></i>
                <h4>We believe in Excellence</h4>
                <p>
                  We set high standards for ourselves. Excellence is not just a
                  goal; it's a standard that permeates everything we do.
                </p>
                <span>3</span>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single-who-we-are">
                <i className="fa fa-scissors"></i>
                <h4>We are Continuous Learner</h4>
                <p>
                  In the ever-evolving IT landscape, we value continuous
                  learning and provide a platform for our team to grow both
                  professionally and personally.
                </p>
                <span>4</span>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single-who-we-are">
                <i className="fa fa-linode"></i>
                <h4>We are Client-Centric</h4>
                <p>
                  Our clients are at the center of our universe. We are
                  dedicated to understanding their needs and delivering
                  solutions that exceed expectations.
                </p>
                <span>5</span>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single-who-we-are">
                <i className="fa fa-life-ring"></i>
                <h4>We are Support</h4>
                <p>
                  Our team of experts is here for you every step of the way.
                  From implementation to ongoing support, we ensure a seamless
                  experience for our clients.
                </p>
                <span>6</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default WhoWeAre;
