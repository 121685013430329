import React from "react";
import imgOne from "../../assets/images/services-img1.jpg";
import imgTwo from "../../assets/images/services-img2.jpg";
import imgThree from "../../assets/images/services-img3.jpg";
import imgFour from "../../assets/images/services-img4.jpg";
import imgFive from "../../assets/images/services-img5.jpg";
import imgSix from "../../assets/images/services-img6.jpg";
import imgSeven from "../../assets/images/services-img7.jpg";
import imgEight from "../../assets/images/services-img8.jpg";
import imgNine from "../../assets/images/services-img9.jpg";

class Services extends React.Component {
  render() {
    return (
      <section id="services" className="services-area ptb-80">
        <div className="container">
          <div className="section-title">
            <h2>
              Our <span>Services</span>
            </h2>
            <p>
              we offer a comprehensive suite of services designed to meet your
              digital needs. From branding and marketing to development and
              design, we're here to elevate your online presence and drive
              success. Explore our range of services below:
            </p>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-services">
                <div className="services-img">
                  <img src={imgOne} alt="services-img" />

                  <div className="icon">
                    <i className="fa fa-pencil-square-o"></i>
                  </div>
                </div>

                <div className="services-content">
                  <h3>Web Design</h3>
                  <p>
                    Build responsive and user-friendly websites that captivate
                    visitors and drive conversions across all devices
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single-services">
                <div className="services-img">
                  <img src={imgTwo} alt="services-img" />

                  <div className="icon">
                    <i className="fa fa-linode"></i>
                  </div>
                </div>

                <div className="services-content">
                  <h3>Branding</h3>
                  <p>
                    Develop a cohesive brand strategy that resonates with your
                    target audience and sets you apart from the competition.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single-services">
                <div className="services-img">
                  <img src={imgThree} alt="services-img" />

                  <div className="icon">
                    <i className="fa fa-desktop"></i>
                  </div>
                </div>

                <div className="services-content">
                  <h3>Marketing</h3>
                  <p>
                    Develop tailored digital marketing strategies to reach your
                    target audience and achieve your business objectives.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single-services">
                <div className="services-img">
                  <img src={imgFour} alt="services-img" />

                  <div className="icon">
                    <i className="fa fa-line-chart"></i>
                  </div>
                </div>

                <div className="services-content">
                  <h3>SEO</h3>
                  <p>
                    Improve your website's visibility on search engines and
                    drive organic traffic with strategic optimization
                    techniques.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single-services">
                <div className="services-img">
                  <img src={imgFive} alt="services-img" />

                  <div className="icon">
                    <i className="fa fa-anchor"></i>
                  </div>
                </div>

                <div className="services-content">
                  <h3>Mobile App Development</h3>
                  <p>
                    Build intuitive and feature-rich mobile applications for iOS
                    and Android devices to expand your reach and enhance user
                    engagement.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single-services">
                <div className="services-img">
                  <img src={imgSix} alt="services-img" />

                  <div className="icon">
                    <i className="fa fa-headphones"></i>
                  </div>
                </div>

                <div className="services-content">
                  <h3>Ui & Ux Design</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single-services">
                <div className="services-img">
                  <img src={imgSeven} alt="services-img" />

                  <div className="icon">
                    <i className="fa fa-mobile"></i>
                  </div>
                </div>

                <div className="services-content">
                  <h3>Responsive Design</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single-services">
                <div className="services-img">
                  <img src={imgEight} alt="services-img" />

                  <div className="icon">
                    <i className="fa fa-camera-retro"></i>
                  </div>
                </div>

                <div className="services-content">
                  <h3>Photography</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
              <div className="single-services">
                <div className="services-img">
                  <img src={imgNine} alt="services-img" />

                  <div className="icon">
                    <i className="fa fa-life-ring"></i>
                  </div>
                </div>

                <div className="services-content">
                  <h3>Custom Support</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Services;
